/*-------- 8. Footer style  -----*/

h2 {
  color: #d1af69;
  font-weight: 600;
}

.redesocial {
  
}

.copyright {
  .footer-logo {
    margin-top: 41px;
    margin-bottom: 12px;

    @media #{$xs-layout} {
      margin-top: 0;
    }

    @media #{$md-layout} {
      margin-top: 0;
    }

    @media #{$lg-layout} {
      margin-top: 28px;
    }
  }

  p {
    letter-spacing: 0.9px;

    color: #313131;

    a {
      color: #313131;

      &:hover {
        color: $theme-color;
      }
    }
  }

  &.copyright-white {
    p {
      color: #fff;

      a {
        color: #fff;

        &:hover {
          color: $theme-color;
        }
      }
    }
  }
}



.footer-widget.ml-30,
.footer-widget.ml-50,
.footer-widget.ml-75 {
  @media #{$md-layout} {
    margin-left: 0;
  }

  @media #{$xs-layout} {
    margin-left: 0;
  }

  @media #{$lg-layout} {
    margin-left: 0;
  }
}



/* home 4 */

.hm4-footer-padding .container-fluid {
  padding: 0 60px;

  @media #{$xx-layout} {
    padding: 0 60px;
  }

  @media #{$xl-layout} {
    padding: 0 30px;
  }

  @media #{$lg-layout} {
    padding: 0 15px;
  }

  @media #{$md-layout} {
    padding: 0 30px;
  }

  @media #{$xs-layout} {
    padding: 0 15px;
  }
}

.footer-area {
  position: relative;

  &.ml-10 {
    margin-left: 0;
  }

  @media #{$xl-layout} {
    &.ml-70 {
      margin-left: 15px;
    }

    &.mr-70 {
      margin-right: 15px;
    }
  }

  @media #{$lg-layout} {
    &.ml-70 {
      margin-left: 30px;
    }

    &.mr-70 {
      margin-right: 30px;
    }
  }

  @media #{$md-layout} {
    &.ml-70 {
      margin-left: 40px;
    }

    &.mr-70 {
      margin-right: 40px;
    }
  }

  @media #{$xs-layout} {
    &.ml-70 {
      margin-left: 15px;
    }

    &.mr-70 {
      margin-right: 15px;
    }
  }
}

/* home 5 */

.footer-top {
  position: relative;
  z-index: 9;

  p {
    line-height: 28px;

    width: 55%;
    margin: 31px auto 33px;

    color: #fffefe;

    @media #{$md-layout} {
      width: 85%;
    }

    @media #{$xs-layout} {
      width: 100%;
    }
  }

  .footer-social {
   
    ul {
      display: flex;
      justify-content: center;
    
      li {
        line-height: 1;

        position: relative;

        margin: 0 23px;

        &:before {
          position: absolute;
          top: 50%;
          right: -30px;

          width: 16px;
          height: 2px;

          content: "";
          transform: translateY(-50%);

          background-color: #7c7b7b;
        }

        &:last-child:before {
          display: none;
        }

        a {
          font-size: 16px;

          color: #e5e5e5;

          &:hover {
            color: $theme-color;
          }
        }
      }
    }

    &.footer-social-white {
      ul {
        li {
          &:before {
            background-color: #fff;
          }
        }
      }
    }
  }

  &.pb-60 {
    @media #{$xs-layout} {
      padding-bottom: 60px;
    }
  }
}

.footer-bottom {
  position: relative;
  z-index: 9;
}

.copyright-2 {
  margin-top: 60px;
  padding-top: 20px;
  padding-bottom: 20px;

  border-top: 1px solid #ccc;

  p {
    line-height: 1;

    color: #fff;

    a {
      color: #fff;

      &:hover {
        color: $theme-color;
      }
    }
  }

  &.copyright-white-border {
    border-top: 1px solid #cccccc;
  }

  &.copyright-gray {
    p {
      color: #848484;

      a {
        letter-spacing: 0.9px;

        color: #848484;

        &:hover {
          color: $theme-color;
        }
      }
    }
  }
}
