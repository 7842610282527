/*----- 20. Cart page  -----*/

.cart-main-area {

  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}
h3.cart-page-title {
  font-size: 20px;
  font-weight: 500;

  margin: 0 0 15px;
}
.icon-color {
  &:disabled {
    cursor:auto ;
    
    opacity: 0.5;
  }

}

.product-thumbnail {
  width: 150px;
}

.product-name {
  color: white;
  text-align: center;

  a {
    color: white !important;
    font-size: 15px;
    font-weight: 500;

    color: white;

    &:hover {
      color: $theme-color;
    }
  }
}

.product-price-cart {
  width: 435px;

  span {
    font-weight: 500;

    color: white;

    &.old {
      margin-right: 10px;

      text-decoration: line-through;

      color: #8e8e8e;
    }
  }
}

.product-message {
  font-weight: 300!important;
  color: lightgray!important;
}

.product-subtotal {
  font-weight: 500;

  color: white;
}

.product-quantity {
  width: 435px;

  .cart-plus-minus {
    position: relative;

    display: inline-block;

    width: 110px;
    height: 40px;
    padding: 0;

    .qtybutton {
      font-size: 16px;

      position: absolute;

      float: inherit;

      width: 30px;
      margin: 0;

      cursor: pointer;
      transition: all 0.3s ease 0s;
      text-align: center;

      color: white;
      border: none;
      background: none;
    }

    .dec.qtybutton {
      top: 0;
      left: 0;

      height: 40px;

      border-right: 1px solid #e5e5e5;
    }

    .inc.qtybutton {
      top: 0;
      right: 0;

      height: 40px;

      border-left: 1px solid #e5e5e5;
    }

    input.cart-plus-minus-box {
      font-size: 14px;

      float: left;

      width: 110px;
      height: 40px;
      margin: 0;
      padding: 0;

      text-align: center;

      color: white;
      border: 1px solid #e1e1e1;
      background: transparent none repeat scroll 0 0;
    }
  }
}

.product-remove {
  width: 100px;

  a,
  button {
    font-size: 17px;

    margin: 0 13px;

    color: white;
    border: none;
    background: none;

    &:hover {
      color: $theme-color;
    }
  }
}

.cart-shiping-update-wrapper {
  display: flex;
  justify-content: space-between;

  padding: 30px 0 55px;
  @media #{$xs-layout} {
    display: block;

    padding: 30px 0 15px;
  }
  .cart-shiping-update > a,
  .cart-clear > button,
  .cart-clear > a {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;

    display: inline-block;

    padding: 18px 63px 17px;

    text-transform: uppercase;

    color: #363f4d;
    border-radius: 50px;
    background-color: #f2f2f2;
    @media #{$md-layout} {
      font-size: 13px;

      padding: 18px 25px 17px;
    }
    @media #{$xs-layout} {
      margin: 0 0 15px;
      padding: 18px 40px 17px;
    }
    &:hover {
      color: #fff;
      background-color: $theme-color;
    }
  }
  .cart-clear > button {
    margin-right: 27px;

    cursor: pointer;
    transition: all 0.3s ease 0s;

    border: medium none;
    &:last-child {
      margin-right: 0;
    }
    @media #{$md-layout} {
      margin-right: 15px;
    }
  }
}


