/*----- 19. Product details page  ---*/

/* affiliate */

/* product-details-slider */

.ribbon {
  position: absolute;
  left: -5px;
  bottom: 5px;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  span {
    color: $theme-color;
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 20px;
      text-align: center;
      width: 80%;
      // box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    }
}
.make-position-relative{
  position: relative;
}
.cart-venda-encerrada-img{
  position: absolute;
  height: 20px;
  width: 100%;

  bottom: 5px;
  background-color: white;
    span {
      color: $theme-color;
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        width: 80%;
        // box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
      }
}



.product-large-image-wrapper {
  position: relative;

  .product-img-badges {
    position: absolute;
    z-index: 3;
    top: 20px;
    left: 20px;

    span {
      font-size: 13px;
      font-weight: 500;
      line-height: 1;

      display: block;

      margin-bottom: 10px;
      padding: 3px 11px;

      color: #fff;
      border-radius: 3px;

      &.pink {
        background-color: #fa6bff;
      }

      &.purple {
        background-color: #a749ff;
      }
    }
  }

  .react_lightgallery_item {
    position: absolute;
    top: 20px;
    right: 20px;

    button {
      font-size: 30px;

      padding: 0;

      border: none;
      background: none;
    }
  }

  &--slider {
    .ht-swiper-button-nav {
      font-size: 50px;

      visibility: hidden;

      padding: 0;

      transition: 0.3s;

      opacity: 0;
      color: #333;
      border: none;
      background: none;
    }

    &:hover {
      .ht-swiper-button-nav {
        visibility: visible;

        opacity: 1;
      }
    }
  }

  .single-image {
    img {
      width: 100%;
    }
  }
}



.fixed-div-price {
  position: fixed;
  z-index: 999;
  min-width: 426px;
  @media #{$xl-layout,$lg-layout,$md-layout} {
    top: 200px;
  }

  @media #{
 $xxs-layout,
  $sm-layout,
  $xs-layout,

}
  {
  position: fixed;
  bottom: 0 !important;
  width: 100%;
  margin-bottom: 15px;
  min-width: 0 !important;
}

  padding: 20px;
  // left: 0;
  background-color: black;

  h2 {
    font-size: 30px ;
    font-weight: bolder;
    line-height: 36px;
      @media #{
       $xxs-layout,
        $sm-layout,
        $xs-layout,
  
      }{
        font-size: 25px;
      }
  }
}

.shop-area {
  padding-top: 5% !important;

  .sticky {
    top: 60px !important;
  }
}