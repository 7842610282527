.pagination {
  display: flex;
  align-items: baseline;
  padding-bottom: 20px;
    ul {
        li {
          display: inline-block;
          margin: 0 4px;

          a,
          button {
            font-size: 10px;
            line-height: 20px;
    
            display: inline-block;
            text-align: center;
            width: 25px;
            height: 28px;
            padding: 0;
    
            text-align: center;
    
            color: $theme-color;
            border: none;
            border-radius: 100% !important;
            background: none;
            box-shadow: 0 0 12px 0.8px rgba(0, 0, 0, 0.1);
    
            &:hover {
              color: #fff;
              background-color: $theme-color;
            }
          }
    
          a.active {
            color: #fff;
            background-color: $theme-color;
            box-shadow: none;
    
            &:hover {
              background-color: #333;
            }
          }
    
          a.prev,
          a.next {
            font-size: 10px;
    
            color: $theme-color;
            background-color: #f6f6f6;
            box-shadow: none;
    
            &:hover {
              color: #fff;
              background-color: $theme-color;
            }
          }
    
          &.page-item {
            &.active {
              .page-link {
                border-color: $theme-color;
                background-color: $theme-color;
                box-shadow: 0 0 12px 0.8px rgba(0, 0, 0, 0.1) !important;
    
                &:focus {
                  box-shadow: none;
                }
              }
            }
    
            .page-link {
              box-shadow: 0 0 12px 0.8px rgba(0, 0, 0, 0.1) !important;
    
              &:focus {
                box-shadow: none;
              }
            }
          }
        }
      }
}
.blockk {
    display: block !important;
    position: absolute;
    margin-top: 20px;
}

.pagination__link {
  background: transparent;
  border: none;
  color: $color-additional;
  height: 28px;
  font-size: 10px;
  padding: 5px;
  text-align: center;
  min-width: 28px;
  transition: all 0.3s;

  @include directify($directions) {
    #{directed('margin-right')}: 2px;
  }

  &:hover {
    color: $color-accent;
    background: transparent;
  }

  &:focus, &:active {
    box-shadow: none;
  }

  svg {
    transition: 0.3s;
  }

  &.pagination__link--arrow {
    border-radius: 50% !important;
    width: 28px;
    height: 28px;

    @include themify($themes) {
      background: themed('colorFieldsBorder');
    }

    &:hover {
      background: $color-accent;

      svg {
        fill: white;
      }
    }

    &:disabled {
      cursor: default;
      opacity: 0.4;

      @include themify($themes) {
        background: themed('colorFieldsBorder');
      }

      svg {
        fill: $color-additional;
      }
    }
  }
}

.pagination__item {

  &.active .pagination__link {
    background-color: transparent;
    font-weight: bolder;
   font-size: 10px;
    color: $theme-color;
  }

  &.disabled {

    .pagination__link svg {

      @include themify($themes) {
        fill: themed('colorIcon');
      }
    }
  }
}

.pagination__link-icon {
  width: 13px;
  height: 13px;
  fill: $color-additional;
}

.pagination__wrap {
  display: flex;
  margin-top: 15px;

  &.pagination__wrap--small {
    margin-top: 0;
    display: block;
  }
}

.pagination-info, .pagination__select-form {
  color: $color-additional;
  font-size: 10px;


  @include directify($directions) {
    #{directed('margin-right')}: 0px;
    #{directed('margin-left')}: 10px;
  }

  select {

    @include themify($themes) {
      background-color: themed('colorBackground');
    }
  }
}

.pagination__item-option {
  color: $color-additional;
  margin-bottom: 20px;
  font-size: 10px;

  @include directify($directions) {
    #{directed('margin-right')}: 0px;
    #{directed('margin-left')}: 10px;
  }
}