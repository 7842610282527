@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

body {
  font-family: 'Roboto',
    sans-serif;
  overflow-x: hidden !important;
  position: relative;
  background-color: black;

}

.main-wrapper {
  width: 100vw;
  height: 100vh;

}
.carousel-indicators button{
    min-height: 10px;
}
.evento-detalhe-subtitle {
  background: #E2D4A5;
  color: #000;
  text-transform: uppercase;
  font-weight: 600;
}

.shows-container-title .h2,
.shows-container-title h2 {
  color: #d1af69;
  font-weight: 600;
  text-transform: uppercase;
}

.header-superior {
  position: relative;

  margin-bottom: 10px;
  background-color: #a80200;
  color: white;
  padding: 2px;

  @media #{$xs-layout,$sm-layout,$xxs-layout} {
    font-size: 10px;
    line-height: 10px;
  }
}
.sobre-ingresso-ol{
  ol{
    color: white;
    li{
      padding-left: 20px;
      a{
        color: $theme-color;
      }
    }
    .sobre-img-container{
      display: grid;
      place-content: center;
      padding-top: 20px;
      p {
          color: white;
          padding: 5px;
        }
    }
    p {
      color: $theme-color;
      font-weight: bold;
      padding: 5px;
    }
  }
}
.comunicado-img-container {
  background-image: url(https://www.toinhabrasilshow.com/wp-content/uploads/2020/09/toinhabrasilshow-comunicado-oficial-covid19-1024x1024.png);
  background-position: -150px bottom;
  background-size: contain;
  background-repeat: no-repeat;
  // min-height: 500px;
}

.adding-padding {
  padding: 0px 3% 0 2% !important;
}
.aviso-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  min-height: 80px;

  @media #{$sm-layout,$xxs-layout,$md-layout,$xs-layout} {
    display: grid;
  }

  .banner-button-container {
    margin-left: 10%;
    @media #{$sm-layout,$xxs-layout,$md-layout,$xs-layout} {
      display: grid;
      place-content: center;
      margin-top: 30px;
      margin-left: 0;
    }

    button {
      border: none;
      min-height: 40px;
      min-width: 150px;
      color: white;
      font-size: bold;
      transition: all 0.3s ease 0s;
      &:hover{
        transition: all 0.3s ease 0s;
        background-color: white !important;
        color: black;
      }

    }
  }

}


.shows-container-title {

  h2 {
    color: #d1af69;
    font-weight: 600;
  }

  ul {
    list-style-type: none;
  }

  li {
    color: #d1af69;
    font-weight: 400;
    font-size: 18px;
    display: inline-block;
    padding: 10px;
  }
}

.parallax {
  /* The image used */
  background-image: url('../imgs/imagemParallex.png');

  /* Set a specific height */
  min-height: 500px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  h2 {
    color: #d1af69;
    font-weight: 600;
    position: relative;
    top: 110px;

  }
}

.facebook {
  font-size: 70px;
  position: relative;
  top: 270px;
  // right: -44%;
  color: #fff;
}

.instagram {
  font-size: 70px;
  position: relative;
  top: 270px;
  // right: -53%;
  color: #fff;
}

.eventos-title {
  font-size: 30px;
  font-size: 2rem;
  color: #d1af69;
}

.eventos-subtitle {
  color: white;
}

.midia-favicon-div {
  display: flex;
  justify-content: space-evenly;
  padding-left: 30%;
  padding-right: 30%;
  align-items: center;

  @media #{$md-layout,$xs-layout,$sm-layout,$xxs-layout} {
    justify-content: space-evenly;
    padding: 0;
  }
}

.search-box {
  -webkit-appearance: none;
  border: none;
  outline: none;
  padding: 10px;
  width: 150px;
  line-height: 30px;
  margin-bottom: 30px;
  background-color: white !important;
}

.contato-info-container {
  background-color: #222222;
  padding: 50px;
  padding-bottom: 80px;

}

.contato-info {
  color: white !important;
  font-size: 18px;
      @media #{$xxs-layout,$sm-layout,$xs-layout} {
     font-size: 14px;
        }
}

.locacao-container {
  
  padding-bottom: 20px;

}

.locacao-img {
  max-width: 250px;
      @media #{$xxs-layout,$sm-layout,$xs-layout,$md-layout} {
          display: block !important;
          width: 100% !important;
          max-width: none;
        }
  &:hover {
    cursor: pointer;
  }
}

.price-tag {
  font-size: 2rem;
  font-weight: 700;
  margin: 0 0 0.2em;
  color: #ffffff;
}

.product-price-evento {
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
}

.price-evento-tag {
  color: white;
  font-weight: bold;
}

.price-name-evento-tag {
  color: white;

}

.price-data-evento-tag {
  color: white;
  margin-left: 10px;
}

.white-text-title {
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.contato-img {
  width: 100%;
  height: 95%;
  object-fit: contain;
  overflow: hidden;

}

.center-button {
  display: grid;
  place-items: center;
}

.comprar-button {
  background-color: rgb(209, 175, 105);
  color: black;
  border: none;
  font-weight: 600;
  min-width: 120px;
  min-height: 35px;
  &:hover {
    background-color: black !important;
    cursor: pointer;
    color: white !important;
    a{
      color: white !important;
    }
  }


}
.icon-color{
    color:white;
    background-color: black
}
.title-forgot-password {
  h3 {
    color: $theme-color;
    font-weight: bold;
  }

}

.pedido-detalhe-acao {
  color: $theme-color  !important;
  background: none;

  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  &:hover {

    cursor: pointer;
  }

}

.nav-toggle {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  a {
    color: white;

    &:hover {
      color: white;
    }
  }
}

.cupom-input {
  max-width: 60%;
  display: inline-block;
    @media #{$xxs-layout,$sm-layout,$xs-layout,$md-layout} {
        display: block !important;
        width: 100% !important;
        max-width: none;
      }

}

.text-bold {
  font-weight: bold;
}

.cupom-card {
  height: 100%
}
.check-out-dados-convidado-container{
  display: flex; 
  justify-content: space-around;
  align-items: center;
    @media #{$xxs-layout,$sm-layout,$xs-layout,$md-layout} {
        display: flex;
        flex-flow: column;
    
     
   
      }
}

.buttun-style-right {
  
  border: none;
  align-items: right;
  background-color: $theme-color;
  min-width: 150px;
  min-height: 50px;
  padding: 0;
  font-weight: bold;
  cursor: pointer;
  margin-top: 15px;
  &:disabled {
    cursor: not-allowed;
  }
         @media #{$xxs-layout,$sm-layout,$xs-layout,$md-layout} {
           display: block !important;
           width: 100% !important;
           max-width: none;
         }

  &:enabled {
    &:hover {

      cursor: pointer;
      background-color: black;
      color: white;
    }
  }

}

.contato-button {

  border: none;
  background-color: $theme-color;
  min-width: 150px;
  min-height: 50px;
  padding: 0;
  font-weight: bold;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  &:enabled {
    &:hover {

      cursor: pointer;
      background-color: white;
      color: black;
    }
  }


}

.finalizar-pagamento-button {

  @media #{$md-layout,$xs-layout,$sm-layout,$xxs-layout} {

    display: block;
    margin: 10px 0;
    padding: 10px;
    width: 100%;
  }
}

.form__field-error {
  color: #ad4444;
  font-size: 13px;
}

.disabled-cupom-button {
  max-width: 60%;
  background-color: $theme-color;
  min-height: 50px;
  padding: 0;
  border: none;

  font-weight: bold;
  cursor: pointer;
    @media #{$xxs-layout,$sm-layout,$xs-layout,$md-layout} {
        display: block !important;
        width: 100% !important;
        max-width: none;
      }
}

.center-modal {
  margin-top: 250px;
  width: 500px;
  height: 300px;
  position: absolute;
  inset: 0 0 0 0;
  //transform: translate(50%, 50%);
}

.cupom-button {
  max-width: 60%;
  background-color: $theme-color;
  min-height: 50px;
  padding: 0;
  border: none;

  font-weight: bold;
  cursor: pointer;
  @media #{$xxs-layout,$sm-layout,$xs-layout,$md-layout} {
    display: block !important;
      width: 100% !important;
      max-width: none;
  }

  &:hover {

    cursor: pointer;
    background-color: black;
    color: white;
  }
}

.descricao-style {
  color: white;
  font-size: 15px;
}

.quebra-de-linha {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.check-out-name {
  align-items: center;
  justify-content: center;
}

.slider-data-container {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 30px;
  width: 50%;
  max-width: 400px;
  max-height: 400;
  text-align: center;


  @media #{$xxs-layout,$xs-layout,$sm-layout,$md-layout} {
    float: center;
    margin-bottom: 50px;
    width: 100%;
    height: 50%;
    max-width:none;
    max-height:none
  }

  .slider-ticket-icon {
    margin-right: 20px;
    font-weight: bolder;
  }

  h2 {
    font-size: 28px;
    text-align: center !important;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    color: white;
    line-height: 20px;
    @media #{$xxs-layout,$xs-layout,$sm-layout,$md-layout} {
          display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
    }
  }

  .slider-btn {
    color: white;
    font-weight: bold;
    margin-top: 20%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: #26af35;

    @media #{$lg-layout,$xs-layout,$md-layout} {
      margin-top: 10px;
      margin-bottom: 60px;
      max-height: 50px;
    }

    &:hover {
      color: #26af35;
      background-color: white;
      cursor: pointer;

      a {
        color: #26af35;
        border: none;
      }
    }

    a {

      max-height: 60px;
      min-width: 300px;
      font-size: 16px;
      line-height: 1;

      text-align: center;

      padding: 19px 50px 21px;

      text-transform: uppercase;

      color: white;


      @media #{$md-layout} {
        padding: 15px 35px 17px;
      }

      @media #{$xs-layout} {
        padding: 15px 35px 17px;

      }


    }
  }

}


.slider-image-container {
  width: 50%;
  padding: 0 !important;
  @media #{$md-layout,
 $xs-layout,
  $sm-layout,
  $xxs-layout
}
  {
  display: grid;
  place-items: center;
 
  width: 100%;
  height: 50%;

}

img {
  position: relative;
  top: 0;
  float: right;
  max-height: 400px;
  min-width:400px;
  object-fit: cover;
  @media #{$md-layout,
 $xs-layout,
  $xxs-layout,
  $sm-layout
}
  {
  min-width: 350px;
  margin-top: 120px;
  max-height: 230px !important;
  object-fit:contain;  
  
  
}
}
}


.order-price {
  font-weight: bold;
}

.logout-button-hover {
  &:hover {
    cursor: pointer;
    color: $theme-color;
  }
}

.accordion-button{
  &:not(.collapsed) {
    color: #222222;
    background-color: #f9f9f9;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  }
  &:focus {
    outline: 0 ;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}


.myaccount-area {
  padding-top: 150px;
  padding-bottom: 80px;

  @media #{$xxs-layout,$sm-layout} {
    padding-top: 60px;
    padding-bottom: 50px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.slider-content-5 {
  p {
    color: white;
  }

  ul {
    li {
      color: white;
    }
  }
}

.cupon-check-out-row {
  margin-left: 5px;

  @media #{$xxs-layout,$sm-layout,$xs-layout,$md-layout} {
    margin: 0;
  }


}

.margin-right-5px {
  margin-right: 5px;

  @media #{$xxs-layout,$sm-layout,$xs-layout,$md-layout} {
    margin: 0;

  }
}

.remove-padding {
  @media #{$xxs-layout,$sm-layout,$xs-layout,$md-layout} {
    padding: 0 !important;
  }
}

.make-pointer-hover {
  display: block;
  margin-bottom: 20px;

  &:hover {
    cursor: pointer;
    border-bottom: 1px solid $theme-color;
  }
}

.loading-spinner {
  color: $theme-color;
}

.color-white {
  color: white;
}
.price-div{
  display: block !important;
  width: 80% !important;
  padding: 10px
}

.evento-detalhe-price-div {
  background: #F8FCF8;
  color: black;
  font-weight: 600;

  p {
    color: black;
    font-weight: 600;
  }
}
.venda-externa-div{
    background: #F8FCF8;
      text-align: center;
}