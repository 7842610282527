/*------- 6. Product style  --------*/
.product-area {
  position: relative;
  .row {
    &.three-column {
      .col-xl-3 {
        flex: 1 0 33.3333%;

        max-width: 33.3333%;

        transition: 0.5s;

        @media #{$lg-layout} {
          flex: 1 0 33.3333%;

          max-width: 33.3333%;
        }
        @media #{$md-layout} {
          flex: 1 0 50%;

          max-width: 50%;
        }
        @media #{$xs-layout} {
          flex: 1 0 100%;

          max-width: 100%;
        }
      }
    }
    &.five-column {
      .col-xl-3 {
        flex: 1 0 20%;

        max-width: 20%;

        transition: 0.5s;

        @media #{$lg-layout} {
          flex: 1 0 33.3333%;

          max-width: 33.3333%;
        }
        @media #{$md-layout} {
          flex: 1 0 50%;

          max-width: 50%;
        }
        @media #{$xs-layout} {
          flex: 1 0 100%;

          max-width: 100%;
        }
      }
    }
  }

  &--style2 {
    .swiper-slider-navigation-wrapper {
      width: 100%;

      text-align: center;
    }
    .ht-swiper-button-nav {
      font-size: 28px;
      line-height: 1;

      position: absolute;
      top: 15px;
      right: 0;

      display: inline-block;

      width: 35px;
      height: 35px;
      padding: 0;

      transition: all 0.3s ease 0s;
      text-align: center;

      color: #c6c6c6;
      border: 1px solid #d2d2d2;
      border-radius: 5px;
      background-color: #f9f9f9;
      i {
        padding-top: 3px;
      }
      @media #{$xs-layout} {
        margin-top: 15px;
      }
      &:hover {
        color: #fff;
        border: 1px solid $theme-color;
        background-color: $theme-color;
      }
      &.swiper-button-prev {
        right: 40px;
        left: auto;
        @media #{$xs-layout} {
          margin-right: 10px;
        }
      }

      @media #{$xs-layout} {
        position: static;

        text-align: center;
      }
    }
  }
}

.product-wrap,
.product-list-image-wrap {
  position: relative;
  .product-img {
    position: relative;

    overflow: hidden;
    img {
      width: 100%;
      max-width: 23rem;
      height: 23rem;
      object-fit: cover;
    }

    &-badges {
      position: absolute;
      top: 12px;
      right: 12px;
      span {
        font-size: 13px;
        font-weight: 500;
        line-height: 1;

        display: block;

        margin-bottom: 10px;
        padding: 3px 11px;

        color: #fff;
        border-radius: 3px;
        &.pink {
          background-color: #fa6bff;
        }
        &.purple {
          background-color: $theme-color;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .hover-img {
      position: absolute;
      top: 0;
      left: 50%;

      visibility: hidden;

      transition-duration: 0.7s;
      transform: translate(-50%, 20px);

      opacity: 0;
    }
    .product-action {
      position: absolute;
      z-index: 9;
      bottom: 0;
      left: 50%;

      display: flex;
      align-items: center;
      flex-wrap: wrap;

      width: 100%;

      transform: translateX(-50%);
      > div {
        display: flex;
        visibility: hidden;
        align-items: center;
        justify-content: center;

        height: 48px;

        transition: all 0.5s ease;
        transform: translateY(20px);

        opacity: 0;
        background-color: $theme-color;
        // &:hover {
        //     background-color: #000;
        // }
      }
      .pro-wishlist {
        width: 48px;

        border-right: 1px solid rgba(255, 255, 255, 0.2);
      }
      .pro-cart {
        font-size: 14px;

        width: calc(100% - 48px - 48px);

        transition-delay: 0.1s;
      }
      .pro-quickview {
        width: 48px;
        margin: 0;

        transition-delay: 0.2s;

        border-left: 1px solid rgba(255, 255, 255, 0.2);
      }
      .pro-same-action {
        a,
        button {
          font-size: 16px;
          line-height: 48px;

          display: block;

          width: 100%;
          height: 48px;

          text-align: center;
          text-transform: capitalize;

          color: #fff;
          border: none;
          background: none;
          &:hover,
          &.active {
            background-color: #000;
          }
        }
        &.pro-wishlist,
        &.pro-quickview {
          a {
            margin-top: 2px;
          }
        }
      }
    }
  }
  .product-content {
    margin: 20px 0 0;
    h3 {
      font-size: 16px;

      margin: 0;
    }
    .product-rating {
      margin: 3px 0 3px;
      i {
        font-size: 17px;

        margin: 0 3px;

        color: #5f5d5d;
        &.yellow {
          color: #ffa900;
        }
      }
    }
    .product-price {
      span {
        font-size: 15px;
        font-weight: 500;

        position: relative;

        margin: 0 9px;

        color: #000;
        &::before {
          position: absolute;
          top: 10px;
          right: -13px;

          width: 7px;
          height: 2px;

          content: "";

          background-color: #000;
        }
        &:last-child:before {
          display: none;
        }
        &.old {
          text-decoration: line-through;

          color: #8e8e8e;
        }
      }
    }
  }
  &:hover .hover-img {
    visibility: visible;

    transform: translate(-50%, 0);

    opacity: 1;
  }
  &:hover .product-action > div {
    visibility: visible;

    transform: translateY(0);

    opacity: 1;
  }
}

.modal-dialog {
  width: 960px;
  max-width: 960px;
  margin: 8% auto;
  padding: 35px;
  @media #{$md-layout} {
    width: 720px;
    max-width: 720px;
  }
  @media #{$xs-layout} {
    width: 100%;
    max-width: 100%;
    padding: 35px 0;
  }
  .modal-header {
    background-color: black;
    .close {
      cursor: pointer;

      opacity: 1;
      color: #333;
      &:hover {
        color: $theme-color;
      }
    }
  }
  .modal-body {
    padding: 35px 15px;
    background-color: black;
  }
  .quickview-big-img {
    img {
      width: 100%;
    }
  }
}
.descricao-produto-container {
  margin: 10% 0 10% 0;
}
.descricao-produto {
  color: white;
  font-weight: bold;
}
.product-details-content {
  display: grid;
  place-items: center;
  min-width: 100%;
  @media #{$lg-layout} {
    &.ml-70 {
      margin-left: 0;
    }
  }
  @media #{$md-layout} {
    &.ml-70 {
      margin-left: 0;
    }
  }
  @media #{$xs-layout} {
    &.ml-70 {
      margin-top: 30px;
      margin-left: 0;
    }
  }
  &.quickview-content {
    @media #{$xs-layout} {
      margin-top: 30px;
    }
  }
  h2 {
    font-size: 24px;
    line-height: 1;

    margin: 0 0 0;

    color: white;
  }
  .product-details-price {
    display: flex;
    align-items: center;

    margin: 15px 0 26px;
    span {
      font-size: 24px;

      color: #fe5252;
      &.old {
        font-size: 18px;

        margin-left: 20px;

        text-decoration: line-through;

        color: white;
      }
    }
  }
  .pro-details-rating-wrap {
    display: flex;
    align-items: center;

    margin: 0 0 17px;
    .pro-details-rating {
      position: relative;

      margin-right: 39px;
      &:before {
        position: absolute;
        top: 4px;
        right: -19px;

        display: none;

        width: 2px;
        height: 15px;

        content: "";

        background-color: #d1d1d1;
      }
      i {
        font-size: 17px;

        margin-right: 5px;

        color: #5f5d5d;
        &.yellow {
          color: #ffa900;
        }
      }
    }
    span {
      a {
        font-size: 15px;

        color: $theme-color;
        &:hover {
          color: #000;
        }
      }
    }
  }
  p {
    font-size: 15px;
    line-height: 28px;

    margin: 0;

    color: white;
  }
  .pro-details-list {
    margin: 20px 0 34px;
    padding: 0px 50px 30px 0;

    border-bottom: 1px solid #e5e5e5;

    ul {
      li {
        margin: 0 0 5px;

        color: white;
        &:last-child {
          margin: 0;
        }
      }
    }
  }

  .pro-details-size-color {
    display: flex;
    @media #{$xs-layout} {
      display: block;
    }
    @media #{$sm-layout} {
      display: flex;
    }
    .pro-details-color-wrap {
      margin-right: 20px;
      @media #{$xs-layout} {
        margin-bottom: 20px;
      }
      @media #{$sm-layout} {
        margin-bottom: 0;
      }
      span {
        font-size: 15px;
        font-weight: 500;

        display: block;

        margin: 0 0 15px;
      }
      .pro-details-color-content {
        ul {
          li {
            position: relative;

            display: block;
            float: left;

            width: 14px;
            height: 14px;
            margin-right: 15px;

            cursor: pointer;
            transition: all 0.4s ease 0s;

            border-radius: 50px;
            &:last-child {
              margin-right: 0;
            }
            &.blue {
              border: 1px solid #4798f3;
              background-color: #4798f3;
            }
            &.maroon {
              border: 1px solid #736751;
              background-color: #736751;
            }
            &.gray {
              border: 1px solid #c0c0c0;
              background-color: #c0c0c0;
            }
            &.green {
              border: 1px solid #139c57;
              background-color: #139c57;
            }
            &.yellow {
              border: 1px solid #e28b37;
              background-color: #e28b37;
            }
          }
        }
        &--single {
          position: relative;

          display: inline-block;

          width: 14px;
          height: 14px;
          margin-right: 15px;

          cursor: pointer;
          transition: all 0.4s ease 0s;

          border-radius: 50px;
          &:last-child {
            margin-right: 0;
          }
          input {
            position: absolute;

            height: 100%;

            cursor: pointer;

            opacity: 0;

            &:checked {
              & ~ .checkmark {
                &:after {
                  visibility: visible;

                  opacity: 1;
                }
              }
            }
          }
          &.blue {
            border: 1px solid #4798f3;
            background-color: #4798f3;
          }
          &.maroon {
            border: 1px solid #736751;
            background-color: #736751;
          }
          &.gray {
            border: 1px solid #c0c0c0;
            background-color: #c0c0c0;
          }
          &.green {
            border: 1px solid #139c57;
            background-color: #139c57;
          }
          &.yellow {
            border: 1px solid #e28b37;
            background-color: #e28b37;
          }
          &.white {
            border: 1px solid #333;
            background-color: #fff;
          }
          &.black {
            border: 1px solid #333;
            background-color: #333;
          }
          &.brown {
            border: 1px solid brown;
            background-color: brown;
          }
          .checkmark {
            position: relative;

            display: inline-block;

            width: 14px;
            height: 14px;
            margin: 0;
            &:after {
              position: absolute;
              top: -7px;
              left: -4px;

              visibility: hidden;

              width: 20px;
              height: 20px;

              content: "";
              transition: 0.3s;

              opacity: 0;
              border: 2px solid #333;
              border-radius: 50%;
            }
          }

          &:hover {
            input {
              & ~ .checkmark {
                &:after {
                  visibility: visible;

                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
    .pro-details-size {
      span {
        font-size: 15px;
        font-weight: 500;

        display: block;

        margin: 0 0 10px;
      }
      .pro-details-size-content {
        ul {
          li {
            display: inline-block;

            margin: 0 2px 0 0;

            // list-style: outside none none;
            a {
              font-size: 12px;
              line-height: 1;

              display: inline-block;

              padding: 6px 9px 7px;

              text-transform: uppercase;

              color: #000;
              background-color: #f1f2f6;
              &:hover {
                color: #fff;
                background-color: $theme-color;
              }
            }
          }
        }
        &--single {
          font-size: 12px;
          line-height: 1;

          position: relative;

          display: inline-block;

          margin: 0 5px 0 0;

          text-transform: uppercase;

          color: #000;
          background-color: #f1f2f6;
          &:hover {
            color: #fff;
            background-color: $theme-color;
          }
          input {
            position: absolute;
            top: 0;
            left: 0;

            height: 100%;

            cursor: pointer;

            opacity: 0;

            &:checked {
              & ~ .size-name {
                color: $white;
                background-color: $theme-color;
              }
            }
          }
          .size-name {
            font-size: 12px;
            font-weight: 400;

            width: 100%;
            margin-bottom: 0;
            padding: 8px;
          }
        }
      }
    }
  }
  .pro-details-quality {
    display: flex;
    align-items: center;

    margin-top: 40px;
    margin-bottom: 34px;
    .cart-plus-minus {
      position: relative;

      display: inline-block;
      overflow: hidden;

      width: 80px;
      height: 60px;
      padding: 0;

      border: 1px solid #e8e8e8;
      .qtybutton {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;

        position: absolute;

        float: inherit;

        width: 24px;
        margin: 0;

        cursor: pointer;
        transition: all 0.3s ease 0s;
        text-align: center;

        color: #8f8f8f;
        border: none;
        background: none;
      }
      .dec.qtybutton {
        top: 0;
        left: 0;

        height: 60px;
      }
      .inc.qtybutton {
        top: 0;
        right: 0;

        height: 60px;
      }
      input.cart-plus-minus-box {
        font-size: 14px;

        float: left;

        width: 80px;
        height: 60px;
        margin: 0;
        padding: 0;

        text-align: center;

        color: #8f8f8f;
        border: medium none;
        background: transparent none repeat scroll 0 0;
      }
    }
    .pro-details-cart {
      margin: 0 25px 0 10px;
      @media #{$xs-layout} {
        margin: 0 10px 0 10px;
      }
      @media #{$md-layout} {
        margin: 0 17px 0 10px;
      }
      a,
      button {
        font-weight: bold;
        line-height: 1;

        z-index: 99;

        display: inline-block;

        padding: 23px 38px 23px;

        text-transform: uppercase;

        color: #fff;
        border: none;
        background: none;
        background-color: #343538;
        @media #{$xs-layout} {
          padding: 23px 12px 23px;
        }
        @media #{$md-layout} {
          padding: 22px 22px 22px;
        }
        &:hover {
          border: none;
        }

        &:disabled {
          cursor: not-allowed;
          &:before,
          &:after {
            display: none;
          }
        }
      }
    }
    .pro-details-wishlist {
      a,
      button {
        font-size: 18px;

        color: #000;
        border: none;
        background: none;
        &:hover,
        &.active {
          color: $theme-color;
        }
      }
    }
    .pro-details-compare {
      a,
      button {
        font-size: 18px;

        margin-left: 25px;

        color: #000;
        border: none;
        background: none;
        @media #{$xs-layout} {
          margin-left: 10px;
        }
        &:hover,
        &.active {
          color: $theme-color;
        }
      }
    }
  }
  .pro-details-meta {
    display: flex;

    margin: 0 0 10px;
    span {
      font-size: 15px;

      margin-right: 5px;

      color: #676767;
    }
    ul {
      li {
        display: inline-block;

        margin: 0 10px 0 0;
        a {
          font-size: 15px;

          color: #676767;
          @media #{$xs-layout} {
            font-size: 14px;
          }
          &:hover {
            color: $theme-color;
          }
        }
      }
    }
  }
  .pro-details-social {
    margin: 24px 0 0;
    ul {
      display: flex;
      li {
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
        a {
          font-size: 16px;

          color: #343538;
          &:hover {
            color: $theme-color;
          }
        }
      }
    }
  }
  &.pro-details-slider-content {
    @media #{$xs-layout} {
      &.mt-50 {
        margin-top: 30px;
      }
    }
    .product-details-price {
      justify-content: center;
    }
    p {
      width: 59%;
      margin: 0 auto;
      @media #{$lg-layout} {
        width: 80%;
      }
      @media #{$md-layout} {
        width: 100%;
      }
      @media #{$xs-layout} {
        width: 100%;
      }
    }
    .pro-details-rating-wrap {
      justify-content: center;
    }
    .pro-details-size-color {
      justify-content: center;

      text-align: left;
      @media #{$xs-layout} {
        text-align: center;
        & .pro-details-color-content {
          display: flex;
          justify-content: center;
        }
      }
    }
    .pro-details-quality {
      justify-content: center;
    }
    .pro-details-meta {
      justify-content: center;
    }
    .pro-details-social {
      ul {
        justify-content: center;
      }
    }
  }
}
.filter-active {
  position: relative;
  a,
  button {
    font-size: 18px;
    font-weight: 500;

    color: #010101;
    border: none;
    background: none;
    &:hover,
    &.active {
      color: $theme-color;
    }
    i {
      font-size: 14px;
    }
  }
}

.tab-content.jump {
  .tab-pane {
    display: block;
    overflow: hidden;

    height: 0;

    opacity: 0;
    &.active {
      display: block;
      overflow: visible;

      height: auto;

      opacity: 1;
    }
  }
}
.section-padding-1 .container-fluid {
  padding: 0 70px;
  @media #{$xl-layout} {
    padding: 0 15px;
  }
  @media #{$lg-layout} {
    padding: 0 30px;
  }
  @media #{$md-layout} {
    padding: 0 40px;
  }
  @media #{$xs-layout} {
    padding: 0 15px;
  }
}

/* product hm4 */

.hm4-section-padding .container-fluid {
  padding: 0 70px;
  @media #{$xx-layout} {
    padding: 0 60px;
  }
  @media #{$xl-layout} {
    padding: 0 30px;
  }
  @media #{$lg-layout} {
    padding: 0 15px;
  }
  @media #{$md-layout} {
    padding: 0 30px;
  }
  @media #{$xs-layout} {
    padding: 0 15px;
  }
}
.toggle-item-active,
.toggle-item-active2,
.toggle-item-active3,
.toggle-item-active4,
.toggle-item-active5,
.toggle-item-active6 {
  display: none;
}

.hm5-section-padding {
  .container-fluid {
    padding-right: 0;
    padding-left: 0;
    @media #{$xs-layout} {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  .row {
    margin-right: -10px;
    margin-left: -10px;
    @media #{$xs-layout} {
      margin-right: -15px;
      margin-left: -15px;
    }
  }
  div[class^="col-"] {
    padding-right: 10px;
    padding-left: 10px;
    @media #{$xs-layout} {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

// overflow: hidden;
//     padding-bottom: 20px; isso aqui no col-xl-3

.product-wrap-3 {
  position: relative;

  .product-img {
    position: relative;
    a {
      img {
        width: 100%;
        max-height: 327px;
        object-fit: cover;
        aspect-ratio: 1;
      }
    }
    &-badges {
      position: absolute;
      z-index: 9;
      top: 12px;
      right: 12px;
      span {
        font-size: 13px;
        font-weight: 500;
        line-height: 1;

        display: block;

        margin-bottom: 10px;
        padding: 3px 11px;

        color: #fff;
        border-radius: 3px;
        &.purple {
          background-color: $theme-color;
        }
        &.pink {
          background-color: #fa6bff;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .product-content-3-wrap {
    position: absolute;
    z-index: 8;
    top: 10%;
    right: 10%;
    bottom: 10%;
    left: 10%;

    visibility: hidden;

    transition: all 0.4s ease 0s;
    transform: scale(0.85);

    opacity: 0;
    background: rgb(0 0 0 / 90%) none repeat scroll 0 0;
    .product-content-3 {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 100%;

      transition: all 0.4s ease 0s;
      transform: translate(-50%, -50%);
      text-align: center;
      .product-title {
        h3 {
          font-size: 18px;
          line-height: 1;

          margin: 0;
        }
      }
      .price-3 {
        margin: 10px 0 20px;
        span {
          font-size: 16px;
          font-weight: 500;

          position: relative;

          margin: 0 9px;

          color: #000;
          &::before {
            position: absolute;
            top: 10px;
            right: -13px;

            width: 7px;
            height: 2px;

            content: "";

            background-color: #000;
          }
          &:last-child::before {
            display: none;
          }
          &.old {
            text-decoration: line-through;

            color: #8e8e8e;
          }
        }
      }
      .product-action-3 {
        .pro-same-action {
          display: inline-block;
        }
        a,
        button {
          font-size: 16px;
          line-height: 42px;

          display: inline-block;

          width: 42px;
          height: 42px;
          margin: 0 3px;

          transition: all 0.4s ease-in-out;
          text-align: center;

          color: #fff;
          border: none;
          border-radius: 50px;
          background: none;
          background-color: #000000;
          &:hover,
          &.active {
            background-color: #2f2f2f;
          }
          &:disabled {
            cursor: not-allowed;
          }
        }
      }
    }
  }
  &:hover .product-content-3-wrap {
    visibility: visible;

    transform: scale(1);

    opacity: 1;
  }
}

.custom-row-4 {
  display: flex;
  flex-wrap: wrap;
}
.custom2-col-5 {
  position: relative;

  flex: 0 0 20%;

  width: 100%;
  max-width: 20%;
  min-height: 1px;
  @media #{$xl-layout} {
    flex: 0 0 25%;

    max-width: 25%;
  }
  @media #{$lg-layout} {
    flex: 0 0 33.333%;

    max-width: 33.333%;
  }
  @media #{$md-layout} {
    flex: 0 0 50%;

    max-width: 50%;
  }
  @media #{$xs-layout} {
    flex: 0 0 100%;

    max-width: 100%;
  }
  @media #{$sm-layout} {
    flex: 0 0 50%;

    max-width: 50%;
  }
}

.bg-png-1 {
  position: absolute;
  top: 0;
  left: 78px;
  @media #{$xs-layout} {
    display: none;
  }
}
.funfact-area {
  position: relative;
}
.bg-png-2 {
  position: absolute;
  top: 0;
  right: 550px;
  @media #{$xx-layout} {
    right: 300px;
  }
  @media #{$xl-layout} {
    right: 250px;
  }
  @media #{$lg-layout} {
    right: 150px;
  }
  @media #{$md-layout} {
    right: 120px;
  }
  @media #{$xs-layout} {
    display: none;
  }
}

.product-quickview-modal-wrapper {
  .modal-dialog {
    margin: 5% auto;
    padding: 0;
  }
}

.category-grid-area {
  margin-top: -100px;

  @media #{$lg-layout} {
    margin-top: -50px;
  }

  @media #{$md-layout} {
    margin-top: 100px;
  }
  @media #{$xs-layout} {
    margin-top: 30px;
  }
}
