/*------ 24. login register Page  --------*/

.login-register-wrapper {
  .login-register-tab-list {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    .nav-item {
      position: relative;

      a {
        background: none;
        padding: 0;

        h4 {
          font-size: 25px;
          font-weight: 700;
          margin: 0 20px;
          text-transform: capitalize;
          transition: all 0.3s ease 0s;
          color: white;
        }

        &:last-child::before {
          display: none;
        }
      }

      a.active h4 {
        color: $theme-color;
      }

      &:before {
        background-color: #454545;
        bottom: 5px;
        content: "";
        height: 18px;
        margin: 0 auto;
        position: absolute;
        right: -2px;
        transition: all 0.4s ease 0s;
        width: 1px;
      }

      &:last-child {
        &::before {
          display: none;
        }
      }
    }
  }

  .login-form-container {
    background: transparent;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    padding: 80px;
    text-align: left;
    border-radius: 1%;

    @media #{$lg-layout} {
      padding: 80px 50px;
    }

    @media #{$xs-layout} {
      padding: 40px 15px;
    }

    .login-register-form {
      form {
        input {
          background-color: white;
          border: 1px solid #ebebeb;
          color: #333;
          font-size: 14px;
          height: 45px;
          margin-bottom: 30px;
          padding: 0 15px;
          border-radius: 5px;
        }

        label {
          color: white;
        }

        input[type="checkbox"] {
          height: 15px;
          margin: 0;
          position: relative;
          top: 1px;
          width: 17px;
        }
      }
    }

    .login-register-form,
    .change-password-form {
      form {
        .change-password-input {
          @media #{$xs-layout} {
            text-align: center;
          }

          label {
            font-weight: 600;
            color: $theme-color;
            margin-top: 20px;
            font-size: 16px;
          }

          .MuiInputBase-input {
            padding: 10px 8px 5px;
            font-size: 15px;
          }

          .MuiInputBase-root {
            @media #{$xs-layout} {
              width: 100%;
            }

            width: 60%;
          }

          .MuiInput-underline:after {
            border-bottom: 2px solid $theme-color;
            margin-top: 10px;
          }
        }

        .button-box {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .change-password {
            margin-top: 25px;
          }

          .login-toggle-btn {
            padding: 10px 0 19px;

            label {
              color: #242424;
              font-size: 15px;
              font-weight: 400;

              @media #{$xs-layout} {
                font-size: 14px;
              }
            }

            a {
              color: $theme-color;
              float: right;
              font-size: 15px;
              padding-left: 10px;

              @media #{$xs-layout} {
                font-size: 14px;
              }

              transition: all 0.3s ease 0s;

              &:hover {
                color: white;
              }
            }
          }

          button {
            background-color: $theme-color;
            border: medium none;

            @media #{$xs-layout} {
              width: 100%;
              margin-bottom: 20px;
            }

            color: white;
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            line-height: 1;
            padding: 11px 30px;
            text-transform: uppercase;
            transition: all 0.3s ease 0s;

            &:hover {
              background-color: white;
              color: black;
            }
          }
        }

        .button-box-click {
          display: flex;
          justify-content: space-around;
          align-items: center;

          @media #{$xxs-layout,$xs-layout,$sm-layout,$md-layout} {
                  display: block;
                  place-content: center;
          }

          .change-password {
            margin-top: 25px;
          }

          .login-toggle-btn {
            padding: 10px 0 19px;

            label {
              color: #242424;
              font-size: 15px;
              font-weight: 400;

              @media #{$xs-layout} {
                font-size: 14px;
              }
            }

            a {
              color: $theme-color;
              
              font-size: 15px;
              @media #{$xxs-layout,$xs-layout,$sm-layout,$md-layout} {
                font-size: 14px;
                padding-left: 5px !important;
                display: grid;
                place-content: center;
           
                
              }

              transition: all 0.3s ease 0s;

              &:hover {
                color: white;
              }
            }
          }

          button {
            background-color: $theme-color;
            border: medium none;
  
            @media #{$xxs-layout,$xs-layout,$sm-layout,$md-layout} {
              display: block !important;
                width: 100% !important;
              margin-bottom: 20px;
            }

            color: white;
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            line-height: 1;
            padding: 11px 30px;
            text-transform: uppercase;
            transition: all 0.3s ease 0s;

            &:enabled {
              &:hover {
                background-color: white;
                color: black;
              }
            }

            &:disabled {
              cursor: not-allowed;
            }

          }
        }
      }
    }
  }

}


.login-register-area {
  display: flex;
  justify-content: center;
  align-items: center;

  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}