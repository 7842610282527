/*----- 3. Slider style  ------*/




.swiper-slide .slider-content * {
  -webkit-animation-duration: 1.3s;
          animation-duration: 1.3s;

  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}



.slider-area {
  &:hover .nav-style-1 .ht-swiper-button-nav {
    visibility: visible;

    opacity: 1;
  }
  &.ml-10 {
    margin-left: 0;
  }
  @media #{$xl-layout} {
    &.ml-70 {
      margin-left: 15px;
    }
    &.mr-70 {
      margin-right: 15px;
    }
  }
  @media #{$lg-layout} {
    &.ml-70 {
      margin-left: 30px;
    }
    &.mr-70 {
      margin-right: 30px;
    }
  }
  @media #{$md-layout} {
    &.ml-70 {
      margin-left: 40px;
    }
    &.mr-70 {
      margin-right: 40px;
    }
  }
  @media #{$xs-layout} {
    &.ml-70 {
      margin-left: 15px;
    }
    &.mr-70 {
      margin-right: 15px;
    }
  }


  .swiper-pagination-bullets {
    position: absolute;
    right: 0;
    bottom: 26px;
    left: 0;

    text-align: center;
    .swiper-pagination-bullet {
      position: relative;

      display: inline-block;

      width: 14px;
      height: 14px;
      margin: 0 8px;

      opacity: 1;
      border-radius: 100%;
      background: #fff none repeat scroll 0 0;
      &:before {
        position: absolute;
        top: -3px;
        right: -3px;
        bottom: -3px;
        left: -3px;

        content: "";

        border: 1px solid transparent;
        border-radius: 100%;
      }
      &-active:before {
        border: 1px solid #fff;
      }
    }
  }
}


.slider-height-4 {
  height: 750px;
  @media #{$xx-layout} {
    height: 600px;
  }
  @media #{$xl-layout} {
    height: 600px;
  }
  @media #{$lg-layout} {
    height: 550px;
  }
  @media #{$md-layout} {
    height: 530px;
  }
  @media #{$xs-layout} {
    height: 500px;
  }

}


.nav-style-2.owl-carousel > .owl-nav div,
.nav-style-2 .ht-swiper-button-nav {
  font-size: 60px;
  line-height: 94px;

  position: absolute;
  top: 50%;

  display: inline-block;
  visibility: hidden;

  width: 65px;
  height: 84px;
  padding: 0;

  transition: all 0.3s ease 0s;
  transform: translateY(-50%);
  text-align: center;

  opacity: 0;
  color: #000;
  border: none;
  background: none;
  background-color: #fff;
  @media #{$lg-layout} {
    font-size: 45px;
    line-height: 74px;

    width: 45px;
    height: 64px;
  }
  @media #{$md-layout} {
    font-size: 45px;
    line-height: 74px;

    width: 45px;
    height: 64px;
  }
  @media #{$xs-layout} {
    font-size: 30px;
    line-height: 54px;

    width: 35px;
    height: 50px;
  }
  &:hover {
    color: #fff;
    background-color: $theme-color;
  }
  &.owl-next {
    right: 0;
    left: auto;
  }

  &.swiper-button-prev {
    left: 0;
  }
  &.swiper-button-next {
    right: 0;
  }
}

.slider-area {
  &:hover .nav-style-2.owl-carousel > .owl-nav div,
  &:hover .nav-style-2 .ht-swiper-button-nav {
    visibility: visible;

    opacity: 1;
  }
  &.ml-10 {
    margin-left: 0;
  }
}













@media #{$xs-layout} {
  .hm-13-slider {
    background-position: 72%;
  }
}

@media #{$sm-layout} {
  .hm-13-slider {
    background-position: 55%;
  }
}

@media #{$md-layout} {
  .res-mrg-md-mb {
    margin-bottom: 30px;
  }
}
@media #{$xs-layout} {
  .res-mrg-md-mb {
    margin-bottom: 30px;
  }
}


.ht-swiper-button-nav {
  &:after {
    font-size: 0;
  }
}

