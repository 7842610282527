/*------ 22. contact Page  ------*/

.contact-map {
  position: relative;

  height: 560px;
  @media #{$xs-layout} {
    height: 400px;
  }
}





