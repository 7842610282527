@import "~bootstrap/scss/bootstrap.scss";
// @import "~swiper/css/swiper.css";
// @import "~animate.css/animate.min.css";
@import "~lightgallery.js/dist/css/lightgallery.css";
// @import "~react-modal-video/scss/modal-video.scss";
@import "../css/icons.min.css";

@import "variables";
@import "helper";
@import "common";

@import "header";
@import "slider";
@import "support";
@import "section-title";
@import "product";
@import "pagination";
@import "footer";
@import "table";
@import "preloader";

@import "image-slider";

    

@import "breadcrumb";
@import "sidebar";
@import "shop";
@import "product-details";
@import "cart";

@import "checkout";
@import "contact";

@import "login-register";
@import "my-account";
@import "404";

@import "general";
