/*------- 21. Checkout page  -----------*/


.your-order-area {
  @media #{$md-layout} {
    margin-top: 30px;
  }
  @media #{$xs-layout} {
    margin-top: 30px;
  }
  h3 {
    font-size: 20px;
    font-weight: 500;

    margin: 0 0 20px;

    color: #000;
  }
  .your-order-wrap {
    padding: 38px 45px 44px;

    background: white;
    @media #{$lg-layout} {
      padding: 30px 20px 36px;
    }
    @media #{$xs-layout} {
      padding: 30px 20px 36px;
    }
    .your-order-product-info {
      .your-order-top {
        ul {
          display: flex;
          justify-content: space-between;
          li {
            font-size: 16px;
            font-weight: 500;

            // list-style: outside none none;
          }
        }
      }
      .your-order-middle {
        margin: 29px 0;
        padding: 19px 0 18px;

        border-top: 1px solid #dee0e4;
     
        ul {
          li {
            display: flex;
            justify-content: space-between;

            margin: 0 0 10px;
          }
        }
      }
      .your-order-bottom {
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
          li {
            font-size: 14px;
            font-weight: 400;

        
          }
          li.your-order-shipping {
            font-size: 16px;
            font-weight: 400;

            color: #212121;
          }
        }
      }
      .your-order-total {
        margin: 18px 0 33px;
        padding: 17px 0 19px;

        border-top: 1px solid #dee0e4;
        border-bottom: 1px solid #dee0e4;
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
            list-style-type: none;
          li.order-total {
            font-size: 18px;
            font-weight: 500;

            color: #212121;
          }
          li {
            font-size: 16px;
            font-weight: 500;

            // list-style: outside none none;

            color: $theme-color;
          }
        }
      }
    }
  }
  .payment-accordion {
    margin: 0 0 16px;
    &:last-child {
      margin: 0 0 0;
    }
    h4 {
      font-size: 16px;

      margin: 0;

      color: #212121;
      a {
        position: relative;

        display: block;

        color: #212121;
        &:hover {
          color: $theme-color;
        }
      }
    }
    .panel-body {
      padding: 5px 0 0 0;
      p {
        font-size: 14px;

        padding: 0 0 0 10px;

        color: #333;
      }
    }
  }
  .place-order > a,
  .place-order > button {
    font-weight: 500;
    line-height: 1;

    z-index: 9;

    display: block;

    width: 100%;
    padding: 18px 20px;

    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;

    color: #fff;
    border: none;
    border-radius: 50px;
    background: none;
    background-color: $theme-color;
    &:hover {
      background-color: #333;
    }
  }
}

.checkout-area {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}
